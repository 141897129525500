// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quem-sou-js": () => import("./../../../src/pages/quem-sou.js" /* webpackChunkName: "component---src-pages-quem-sou-js" */),
  "component---src-pages-receitas-js": () => import("./../../../src/pages/receitas.js" /* webpackChunkName: "component---src-pages-receitas-js" */),
  "component---src-templates-recipe-index-js": () => import("./../../../src/templates/Recipe/index.js" /* webpackChunkName: "component---src-templates-recipe-index-js" */),
  "component---src-templates-testimony-index-js": () => import("./../../../src/templates/Testimony/index.js" /* webpackChunkName: "component---src-templates-testimony-index-js" */)
}

